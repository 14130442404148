import React from "react";

const MobileNav = ({ refCode, handleModalClose }) => {
  return (
    <div
      className="h-screen overflow-hidden"
      style={{
        background: `linear-gradient(180deg, #2902E3 0%, #5835FF 42.86%, #F3FD7C 100%)`,
      }}
    >
      <div className="text-center">
        <div
          className="py-12"
          role="button"
          onClick={handleModalClose}
          onKeyDown={handleModalClose}
        >
          <img src="/icons/close-x-icon.svg" alt="close" className="w-8 h-8 mx-auto" />
        </div>
        <ul className="space-y-6 items-center text-2xl text-white">
          <li
            role={"button"}
            onClick={() => {
              handleModalClose();
              window.location.href = "#FAQs";
            }}
            onKeyDown={() => {
              handleModalClose();
              window.location.href = "#FAQs";
            }}
          >
            <a href="#FAQs">FAQs</a>
          </li>
          <div>
            <a href={`https://localramp.co/`} target="_blank" className="cursor-pointer text-xl">
              <span className="bg-primary-light text-primary py-3 px-7 rounded-full">
                Developers
              </span>
            </a>
          </div>
        </ul>
      </div>
    </div>
  );
};

export default MobileNav;
