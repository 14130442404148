import React from "react";
import PcHeroCardSmall from "../components/PcHeroCardSmall";
import CardOne from "./HeroCards/CardOne";
import CardTwo from "./HeroCards/CardTwo";

const PcHeader = ({ public_key }) => {
	return (
		<>
			<div className="mt-11 mb-4 mx-2 right-0 left-0 text-center lg:bg-[url('/images/hero-section-bg.svg')] bg-contain bg-no-repeat bg-top bg-none relative">
				<div className="pt-0 md:pt-32">
					<div className="w-full max-w-[787px] mx-auto relative space-y-2 md:space-y-3">
						<CardOne public_key={public_key} />
						<CardTwo public_key={public_key} />
						<div className="grid grid-cols-1 gap-2 md:grid-cols-2">
							<PcHeroCardSmall
								title={"Send money to Africa in minutes"}
								ctaText="Try it out"
								priColour={"#F3FD7C"}
								link="https://app.payouts.cash/?sCurr=usd&am=5"
							/>
							<PcHeroCardSmall
								title={"Receive money with your payment link"}
								ctaText="Try it out"
								priColour={"#228F5F"}
								link="https://app.payouts.cash/payment-link"
							/>
						</div>
					</div>
				</div>
				{/* <div className="relative max-w-md mx-auto mt-5 space-y-4 md:px-4 md:space-y-5 md:pt-7">
          <div
            className="py-6 md:py-7 pc-card-shadow"
            data-aos="fade-up"
            data-aos-duration="900"
            data-aos-delay="400"
            data-aos-anchor-placement="top-bottom"
          >
            <SendReceive
              rates={rates}
              refCode={refCode}
              isLoading={isLoadingPage}
              sourceCurrency={sourceCurrency}
              destinationCurrency={destinationCurrency}
              updateSourceCurrency={setSourceCurrency}
              updateDestinationCurrency={setDestinationCurrency}
            />
          </div>
        </div> */}
			</div>
		</>
	);
};

export default PcHeader;
