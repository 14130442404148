import React from "react";

function PcHeroCardSmall({ title, ctaText, link, priColour }) {
	return (
		<div className="rounded-3xl p-8 bg-[url(/images/card-bg.svg)] bg-cover bg-no-repeat text-white w-full text-left mx-auto">
			<h2 className="text-[32px] md:text-[38px] leading-10 md:leading-[47.5px] karl-font text-left mb-8">{title}</h2>
			<div className="space-x-4">
				<button
					onClick={() => window.open(link, "_blank")}
					className={`text-sm md:text-base text-white py-3 px-6 rounded-3xl`}
					style={{ backgroundColor: priColour }}
				>
					<div className="flex items-center justify-center space-x-2">
						<span className=" text-text-primary">{ctaText}</span>
					</div>
				</button>
				<a href="https://www.youtube.com/watch?v=l9qQAOqdwQI" target="_blank">
					<span className="text-sm font-medium md:text-base" style={{ color: priColour }}>
						Watch a demo
					</span>
				</a>
			</div>
		</div>
	);
}

export default PcHeroCardSmall;
