import React from "react";
import { PcTag } from "../components";
import Popup from "reactjs-popup";
import { commalize, findCoinLogo, getFlagsFor } from "../utility";

function RateTag({ selectedSource, selectedDestination, calculatedRate, isLoading, setSelectedDestination, destinationCurrencies }) {
	return (
		<PcTag>
			<div className="flex items-center min-w-[150px] justify-center py-2 space-x-2 text-sm font-semibold text-center text-white md:text-base">
				<button className="inline-block">
					1 <span className="uppercase">{selectedSource}</span>
				</button>
				<div className="text-sm text-medium">
					<img src="/icons/arrow-bidir.svg" alt="convert" width={16} height={16} />
				</div>
				{isLoading ? (
					<img src="/icons/loader.svg" alt="" className="w-4 h-4 animate-spin" />
				) : (
					<Popup
						trigger={
							<button className="flex items-center gap-2">
								<span className="uppercase">
									{selectedDestination} {commalize(calculatedRate)}
								</span>
								<img src="/icons/dropdown-white.svg" alt="" className="w-4 h-4 text-white stroke-white" />
							</button>
						}
						position="bottom center"
						closeOnDocumentClick
						arrow={false}
					>
						<CurrencyDropdownList onItemClick={setSelectedDestination} currencies={destinationCurrencies} />
					</Popup>
				)}
			</div>
		</PcTag>
	);
}

const CurrencyDropdownList = ({ onItemClick, currencies }) => {
	return (
		<div className="rounded-lg bg-white min-w-[180px] max-h-[300px] overflow-y-scroll">
			{currencies?.map((currency, index) => (
				<DropdownCurrencyItem currency={currency} key={index} onClick={onItemClick} />
			))}
		</div>
	);
};

const DropdownCurrencyItem = ({ currency, onClick }) => {
	return (
		<button
			className="flex items-center justify-between w-full px-6 py-3 hover:bg-slate-200 border-b border-[#0C18251A]"
			onClick={() => onClick(currency)}
		>
			<img
				src={currency?.fiat ? getFlagsFor(currency?.country_code) : findCoinLogo(currency)}
				alt="currency"
				className="w-8 h-8 rounded-full"
			/>
			<p>{currency?.fiat || currency?.chain}</p>
		</button>
	);
};

export default RateTag;
