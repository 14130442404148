import React, { useState } from "react";
import RateTag from "../RateTag";
import { useQuery } from "@tanstack/react-query";
import { getSellCurrencies, getSellQoute, handleSellClick, handleSellClickMobile } from "../../api";

const CardTwo = ({ public_key }) => {
	const [isInitiating, setIsInitiating] = useState(false);
	const [selectedSource] = useState({ currency: "USDT" });
	const [selectedDestination, setSelectedDestination] = useState(null);

	const { data: currencies, isLoading } = useQuery({
		queryKey: ["sell-curencies"],
		queryFn: () => getSellCurrencies({ public_key }),
		onSuccess: (data) => {
			setSelectedDestination(data?.receiver_currency?.at(0));
		},
		retry: false,
		refetchOnWindowFocus: false,
	});

	const { data: quotes, isLoading: isQuotesLoading } = useQuery({
		queryKey: ["quotes", selectedSource, selectedDestination],
		queryFn: () => getSellQoute({ to_currency: selectedDestination?.fiat, from_currency: selectedSource?.currency, public_key }),
		enabled: !!selectedSource && !!selectedDestination,
		retry: false,
		refetchOnWindowFocus: false,
	});

	const handleTrySellClick = () => {
		setIsInitiating(true);
		handleSellClick({ to_currency: selectedDestination?.fiat, country_code: selectedDestination?.country_code, public_key })
			.then((result) => {
				setIsInitiating(false);
				if (!result?.data?.data?.checkout_link) return true;

				const popupWindow = window.open(result?.data?.data?.checkout_link, "_blank");

				const isPopupBlocked = popupWindow === null || typeof popupWindow === "undefined";

				if (isPopupBlocked) {
					handleSellClickMobile({ to_currency: selectedDestination?.fiat, country_code: selectedDestination?.country_code, public_key });
				}
			})
			.catch((e) => {
				setIsInitiating(false);
				console.log(e?.message);
			});
	};

	return (
		<div className="w-full p-8 flex-col space-y-6 md:space-y-0 md:flex-row flex md:items-center md:justify-between bg-[url('/images/heroCards/gradientGreen.png')] bg-cover bg-no-repeat bg-top rounded-2xl">
			<div className="space-y-6">
				<p className="text-[32px] md:text-[40px] karl-font text-left max-w-[280px] md:max-w-[460px] text-white leading-10 md:leading-[47.5px]">
					Sell your crypto instantly for cash
				</p>
				<div className="items-center justify-start hidden space-x-5 text-left md:flex">
					<button onClick={handleTrySellClick} className={`text-white flex items-center gap-2 py-3 px-6 rounded-3xl bg-[#228F5F]`}>
						Try it out
						{isInitiating && <img src="/icons/loader.svg" alt="" className="w-4 h-4 animate-spin" />}
					</button>
					<a href="https://www.youtube.com/watch?v=l9qQAOqdwQI" target={"_blank"}>
						<span className="text-base font-medium text-white"> Watch a demo</span>
					</a>
				</div>
			</div>
			<div className="ml-0 mr-auto md:ml-auto md:mr-0">
				{!isLoading && (
					<RateTag
						selectedSource={selectedSource?.currency}
						selectedDestination={selectedDestination?.fiat}
						setSelectedDestination={setSelectedDestination}
						isLoading={isQuotesLoading}
						calculatedRate={quotes?.rate?.amount || 0}
						destinationCurrencies={currencies?.receiver_currency}
					/>
				)}
			</div>
			<div className="flex items-center justify-start w-full space-x-5 md:hidden">
				<button
					onClick={handleTrySellClick}
					className={`text-sm md:text-base text-white flex items-center gap-2 py-3 px-6 rounded-3xl bg-[#228F5F]`}
				>
					Try it out
					{isInitiating && <img src="/icons/loader.svg" alt="" className="w-4 h-4 animate-spin" />}
				</button>
				<a href="https://www.youtube.com/watch?v=l9qQAOqdwQI" target={"_blank"}>
					<span className="text-sm font-medium text-white md:text-base"> Watch a demo</span>
				</a>
			</div>
		</div>
	);
};

export default CardTwo;
