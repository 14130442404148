import React, { useState } from "react";
import { getBuyCurrencies, getBuyQoute, handleBuyClick, handleBuyClickMobile } from "../../api";
import RateTag from "../RateTag";
import { useQuery } from "@tanstack/react-query";
import { before_ } from "../../utility";

const CardOne = ({ public_key }) => {
	const [isInitiating, setIsInitiating] = useState(false);
	const [selectedSource] = useState({ currency: "USDT_ETH" });
	const [selectedDestination, setSelectedDestination] = useState(null);

	const { data: currencies, isLoading } = useQuery({
		queryKey: ["curencies"],
		queryFn: () => getBuyCurrencies({ public_key }),
		onSuccess: (data) => {
			setSelectedDestination(data?.sender_currency?.at(0));
		},
		retry: false,
		refetchOnWindowFocus: false,
	});

	const { data: quotes, isLoading: isQuotesLoading } = useQuery({
		queryKey: ["quotes", selectedSource, selectedDestination],
		queryFn: () =>
			getBuyQoute({
				sender_currency: selectedDestination?.fiat,
				receiver_currency: selectedSource?.currency,
				public_key,
			}),
		enabled: !!selectedSource && !!selectedDestination,
		retry: false,
		refetchOnWindowFocus: false,
	});

	const handleTryBuyClick = () => {
		setIsInitiating(true);
		handleBuyClick({ sender_currency: selectedDestination?.fiat, country_code: selectedDestination?.country_code, public_key })
			.then((result) => {
				setIsInitiating(false);
				if (!result?.data?.data?.checkout_link) return true;

				const popupWindow = window.open(result?.data?.data?.checkout_link, "_blank");

				const isPopupBlocked = popupWindow === null || typeof popupWindow === "undefined";

				if (isPopupBlocked) {
					handleBuyClickMobile({ sender_currency: selectedDestination?.fiat, country_code: selectedDestination?.country_code, public_key });
				}
			})
			.catch((e) => {
				setIsInitiating(false);
				console.log(e?.message);
			});
	};
	return (
		<div className="w-full p-8 flex-col space-y-6 md:space-y-0 md:flex-row flex md:items-center md:justify-between bg-[url('/images/heroCards/gradientPurple.png')] bg-cover bg-no-repeat bg-top rounded-2xl">
			<div className="space-y-6">
				<p className="text-[32px] md:text-[40px] karl-font text-left max-w-[280px] md:max-w-md text-white leading-10 md:leading-[47.5px]">
					Deposit crypto into any wallet address with fiat
				</p>
				<div className="hidden space-x-5 text-left md:block">
					<button onClick={handleTryBuyClick} className={`text-white py-3 px-6 rounded-3xl bg-primary`}>
						<div className="flex items-center justify-between space-x-1">
							<span>Try it out</span>
							{isInitiating && <img src="/icons/loader.svg" alt="" className="w-4 h-4 animate-spin" />}
						</div>
					</button>
					<a href="https://www.youtube.com/watch?v=syTWcluVKwY" target={"_blank"}>
						<span className="text-base font-medium text-white">Watch a demo</span>
					</a>
				</div>
			</div>
			<div className="ml-0 mr-auto md:ml-auto md:mr-0">
				{!isLoading && (
					<RateTag
						selectedSource={before_(selectedSource?.currency)}
						selectedDestination={selectedDestination?.fiat}
						setSelectedDestination={setSelectedDestination}
						isLoading={isQuotesLoading}
						calculatedRate={quotes?.rate?.exchange_rate || 0}
						destinationCurrencies={currencies?.sender_currency}
					/>
				)}
			</div>
			<div className="flex items-center justify-start w-full space-x-5 md:hidden">
				<button onClick={handleTryBuyClick} className={`text-sm md:text-base text-white py-3 px-6 rounded-3xl bg-primary`}>
					<div className="flex items-center justify-between space-x-1">
						<span>Try it out</span>
						{isInitiating && <img src="/icons/loader.svg" alt="" className="w-4 h-4 animate-spin" />}
					</div>
				</button>
				<a href="https://www.youtube.com/watch?v=syTWcluVKwY" target={"_blank"}>
					<span className="text-sm font-medium text-white md:text-base">Watch a demo</span>
				</a>
			</div>
		</div>
	);
};

export default CardOne;
