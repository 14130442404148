import React from "react";
import PcAccordionItem from "../PcAccordionItem";

function PcAccordion({ data }) {
  return (
    <div className="w-full">
      {data.map(({ heading, content }, i) => (
        <PcAccordionItem key={i} i={i} heading={heading} content={content} />
      ))}
    </div>
  );
}

export default PcAccordion;
