import React, { useState } from "react";

function PcAccordionItem({ i, heading, content }) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div
      tabIndex={i}
      role={"button"}
      onClick={() => setIsOpen(!isOpen)}
      onKeyDown={() => setIsOpen(!isOpen)}
      className="cursor-pointer"
      className="px-4 py-8 md:px-10 md:py-5 bg-primary-light w-full mb-[2px]"
    >
      <div className="flex items-start md:items-center justify-between">
        <span className="text-base md:text-lg font-medium">{heading}</span>
        <div>
          <div className="w-7 md:w-6 h-7 md:h-6">
            {isOpen ? (
              <img src="/icons/icon-close.svg" alt="close" className="w-full h-full" />
            ) : (
              <img src="/icons/icon-open.svg" alt="open" className="w-full h-full" />
            )}
          </div>
        </div>
      </div>
      {isOpen && (
        <div className="mt-3">
          <p className="text-sm md:text-base text-left w-full xl:w-[90%]">{content}</p>
        </div>
      )}
    </div>
  );
}

export default PcAccordionItem;
