import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import AOS from "aos";
import { getPublicKey } from "../api";
import NavigationBar from "../containers/NavigationBar";
import PcFooter from "../containers/PcFooter";
import PcHeader from "../containers/PcHeader";
import PcFAQs from "../containers/PcFAQs";
import MobileNav from "../containers/MobileNav";
import { useQuery, QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient();

function Index(props) {
	const [refCode, setRefCode] = useState(null);
	const [isShowingMobileNav, setIsShowingMobileNav] = useState(false);

	useEffect(() => {
		const params = props.location.search;
		setRefCode(params.substring(params.indexOf("=") + 1));
	}, []);

	useEffect(() => {
		AOS.init({
			duration: 1000,
		});
	}, []);

	const { data, isLoading } = useQuery({
		queryKey: ["pubkey", refCode],
		queryFn: () => getPublicKey(refCode || null),
		refetchOnWindowFocus: false,
	});

	if (isLoading) return null;

	const public_key = data?.public_key;

	return (
		<>
			<Helmet>
				<meta charSet="utf-8" />
				<title>Convert your Naira to crypto | Sell your USDT/BTC for Naira | payouts.cash</title>
				<meta name="description" content="Convert your Naira to crypto | Sell your USDT/BTC for Naira ⚡️" />
				<meta name="keywords" content="payments, crypto, naira, nigeria, remittance, exchange, send, receive, money, convert, transfer" />
				<meta property="og:title" content="Convert your Naira to crypto | Sell your USDT/BTC for Naira | payouts.cash" key="title" />
				<meta property="og:url" content={"payouts.cash"} key="ogurl" />
				<meta property="og:description" content="Convert your Naira to crypto | Sell your USDT/BTC for Naira ⚡️" key="description" />
				<link rel="icon" href="/favicon.ico" />

				<meta property="og:locale" content="en_US" />
				<meta property="og:type" content="website" />
				<meta property="og:image" content="https://ik.imagekit.io/tryswoosh/payouts_cash_bg_1.jpeg" />
				<meta property="og:site_name" content="payouts.cash" />

				<meta property="og:url" content="payouts.cash" />
				<meta property="og:image:secure_url" content="https://ik.imagekit.io/tryswoosh/payouts_cash_bg_1.jpeg" />
				<meta property="og:image:alt" content="Crypto to Cash" />

				<link rel="stylesheet" href="https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.0.0/css/flag-icons.min.css" />

				<meta name="twitter:card" content="summary_large_image" />
				<meta name="twitter:site" content="@trypayouts" />
				<meta name="twitter:title" content="Convert your Naira to crypto | Sell your USDT/BTC for Naira | payouts.cash" />
				<meta name="twitter:description" content="Convert your Naira to crypto | Sell your USDT/BTC for Naira ⚡️" />
				<meta name="twitter:image" content="https://ik.imagekit.io/tryswoosh/payouts_cash_bg_1.jpeg" />

				<meta name="twitter:image:alt" content="payouts.cash SEO Image" />
				<meta property="twitter:url" content="https://payouts.cash" />
			</Helmet>

			<div>
				<div className="overflow-hidden">
					<div className="w-full px-5 py-4 bg-primary-light hidden">
						<p className="font-medium text-center">
							Withdraw {" "}
							<a
								href="#"
								target="_blank"
								className="font-bold tracking-wide text-purple-800 underline decoration-dashed underline-offset-4"
							>
								wallet funds{" "}
							</a>
							from{" "}
							<a
								href="#"
								target="_blank"
								className="font-bold tracking-wide text-red-800 underline decoration-dashed underline-offset-4"
							>
								old payouts.cash
							</a>
						</p>
					</div>
				</div>

				{isShowingMobileNav ? (
					<MobileNav refCode={refCode} handleModalClose={() => setIsShowingMobileNav(false)} />
				) : (
					<>
						<main className="px-4 mx-auto overflow-x-hidden bg-white max-w-screen-2xl md:px-14 text-text-primary">
							<NavigationBar refCode={refCode} handleBurgerClick={() => setIsShowingMobileNav(true)} />
							<PcHeader public_key={public_key} />
							{/* <PostHero /> */}
							{/* <HowMagicHappens refCode={refCode} />
              <QuickAndSmoothTransfer /> */}
							<PcFAQs />
						</main>
						<PcFooter />
					</>
				)}
			</div>
			<Helmet>
				<script src="https://cdn.localramp.co/onramp-inline.js" />
			</Helmet>
		</>
	);
}

export default Index;
