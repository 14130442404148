import React from "react";

const PcFooter = () => {
  return (
    <footer className="max-w-screen-2xl mx-auto px-7 text-white pt-8 md:pt-4 pb-10 bg-[#232323] mt-12">
      <div className=" bg-[url(/images/mobile-footer.png)] md:bg-[url(/images/footer.png)] bg-contain bg-no-repeat md:bg-center bg-right-top">
        <div className="px-0 flex flex-wrap xl:flex-nowrap justify-between items-end lg:px-10 xl:px-28">
          <div className="space-y-6 md:space-y-8">
            <p className="text-lg md:text-xl lg:text-2xl tracking-wide">
              payouts<span className="opacity-40">.cash</span>
            </p>
            <div className="space-x-2 flex flex-row items-end">
              {/*<a href={"https://facebook.com/payoutsdotcash"} target="_blank">
                <img src="/icons/Frame-1.svg" alt="close" className="w-4 h-4 md:w-6 md:h-6" />
              </a>*/}
              <a href={"https://twitter.com/trypayouts"} target="_blank">
                <img src="/icons/Frame-2.svg" alt="close" className="w-4 h-4 md:w-6 md:h-6" />
              </a>
              {/*<a href={"https://instagram.com/payoutsdotcash"} target="_blank">
                <img src="/icons/Frame.svg" alt="close" className="w-4 h-4 md:w-6 md:h-6" />
              </a>
              <a href={"https://www.linkedin.com/company/payouts-cash"} target="_blank">
                <img src="/icons/Frame-3.svg" alt="close" className="w-4 h-4 md:w-6 md:h-6" />
              </a>
              */}
              <p className="text-sm md:text-sm lg:text-base">@trypayouts</p>
            </div>
          </div>
          <p className="text-sm order-2 lg:order-none md:text-sm lg:text-base mt-12 lg:mt-0">
            © 2024 payouts.cash All rights reserved
          </p>
          <p className="text-sm md:text-sm lg:text-base mt-4 xl:mt-0">
            <span
              className="font-light cursor-pointer"
              onClick={(e) => {
                window.location.href = "mailto:support@payouts.cash";
                e.preventDefault();
              }}
              role={"button"}
            >
              Contact us:
            </span>{" "}
            support@payouts.cash
          </p>
        </div>
      </div>
    </footer>
  );
};

export default PcFooter;
