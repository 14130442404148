import React from "react";

function NavigationBar({ refCode, handleBurgerClick }) {
  return (
    <div className="flex justify-between items-center pt-10 opacity-100 xl:px-16">
      <div>
        <div
          className="flex items-center justify-start space-x-2 w-48 mx-auto"
          data-aos="fade-right"
          data-aos-duration="400"
        >
          <img src={"/images/symbol.svg"} width={25} height={25} />
          <div className="text-primary-dark text-2xl text-center">
            payouts<span className="text-text-secondary">.cash</span>
          </div>
        </div>
      </div>
      <div className="hidden lg:inline-block">
        <ul className="flex space-x-10 items-center text-base font-normal">
          <a href="#FAQs">FAQs</a>
          <a href={`https://localramp.co/`} target="_blank" className="cursor-pointer text-sm">
            <span className="bg-primary-light text-primary font-medium py-3 px-7 rounded-full">
              Developers
            </span>
          </a>
        </ul>
      </div>
      <div
        onClick={handleBurgerClick}
        onKeyDown={handleBurgerClick}
        role="button"
        className="lg:hidden inline-block"
      >
        <img src="/icons/burger-icon.svg" alt="burder" className="w-6 md:w-8 h-6 md:h-8" />
      </div>
    </div>
  );
}

export default NavigationBar;
