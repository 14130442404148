import React from "react";
import PcAccordion from "../components/PcAccordion";

const PcFAQs = () => {
  const FAQs = [
    {
      heading: "What can I do on payouts.cash?",
      content:
        `Buy over 120 cryptocurrencies with your local currency..........Instantly sell your Bitcoin and USDT for cash.
      `,
    },
    {
      heading: "What are your fees for buying crypto?",
      content:
        `N100 + Network Fees on the blockchain
      `,
    },
    {
      heading: "What are your fees for selling crypto?",
      content:
        `1.7USDT + N60`,
    },
    {
      heading: "How can I reduce fees when selling crypto?",
      content:
        `If you sell crypto from your payouts.cash wallet, the 1.7 USDT fee is waived.`,
    },
    {
      heading: "How does the sell crypto service work?",
      content:
        `For each transaction, we provide a unique address for you. Once you transfer your crypto to that address and click the "I have made payment" button, the cash will get to you immediately`,
    },
    {
      heading: "What coins and networks do you support for buying?",
      content:
        "You can buy over 120+ coins on 70+ networks",
    },
    {
      heading: "What coins and networks do you support for selling?",
      content:
        "You can sell Bitcoin or USDT as either TRC20 or BEP20 i.e Tron Network and Binance Smart Chain.",
    },
    {
      heading: "How long does it take?",
      content:
        `You should typically get the cash/crypto within 2 minutes of blockchain confirmation.`,
    },
    {
      heading: "What are your limits for selling?",
      content:
        `$2,000. For higher limts contact support@payouts.cash`,
    },
    {
      heading: "What are your buy limits?",
      content:
        "Between $10 to $12000 depending on your KYC level.",
    },
    {
      heading: "Do you have APIs?",
      content:
        `Yeah we do. To get access, shoot an email to integrations@payouts.cash`,
    },
    {
      heading: "How can I reach you?",
      content:
        `hello@payouts.cash. We're just an email away.`,
    },
  ];
  return (
    <section id="FAQs" className="py-20 pt-16 md:pt-36">
      <h2 className="lg:text-5xl">Frequently Asked Questions</h2>
      <div className="max-w-5xl px-3 mx-auto">
        <PcAccordion data={FAQs} />
      </div>
    </section>
  );
};

export default PcFAQs;
